import styles from "./Tablet.module.scss";
import { ButtonTypeB as Button } from "../../../../../Buttons";
import { useSendToSection } from "../../../../../../hooks";
import { useHoverImageSwap } from "../../hooks";

const Tablet = ({ content, idx }) => {
    const {
        mainImg, gallery, heading, description,
        features, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    const hoverImageSwap = useHoverImageSwap();
    
    return (
        <section className={`${styles.product} ${mirrored ? styles.reverse : ""}`}>
            <div className={styles.titleBlock}>
                <h2 className={styles.heading}>{heading}</h2>
            </div>
            <div className={styles.container}>
                <div className={`${styles.hero} ${mirrored ? styles.reverse : ""}`}>
                    <img id={`main-img-${idx}`} src={mainImg.src} alt={mainImg.alt} />
                </div>
                <div className={`${styles.gallery} ${mirrored ? styles.reverse : ""}`}>
                    {gallery.map(({ src, alt }, id) => (
                        <img key={id} src={src} alt={alt} />
                    ))}
                </div>
                <div className={`${styles.content} ${mirrored ? styles.reverse : ""}`}>
                    <div className={styles.description}>
                        {description.map((paragraph, id) => (
                            <p key={id}>{paragraph}</p>
                        ))}
                    </div>
                    <ul className={styles.features}>
                        {features.map((feature, id) => (
                            <li key={id}>{feature}</li>
                        ))}
                    </ul>
                </div>
                <div className={styles.cta}>
                    <Button
                        text={cta.text}
                        target={cta.target}
                        onClick={() => sendToSection("page-content", cta.target)}
                    />
                </div>
            </div>
        </section>
    )
}

export default Tablet;