import { useMemo } from "react";

const useRenderStars = (rating, starImgSrc, iconSize = 40) => {
    return useMemo(() => {
        const fullStars = Math.floor(rating);
        const partial = rating - fullStars;
        const stars = [];
        
        for (let i = 0; i < fullStars; i++) {
            stars.push(
                <img
                    key={`full-${i + 1}`}
                    src={starImgSrc} alt="star"
                    style={{ width:`${iconSize}px`, height: `${iconSize}px`}}
                />
            );
        }
        
        if (partial > 0) {
            stars.push(
                <div
                    key={"partial"}
                    style={{
                        position: "relative",
                        display: "inline-block",
                        width: `${iconSize}px`,
                        height: `${iconSize}px`,
                        overflow: "hidden"
                    }}
                >
                    <img
                        src={starImgSrc}
                        alt="partial-star"
                        style={{
                            width: "100%",
                            height: "100%",
                            clipPath: `inset(0 ${100 - partial * 100}% 0 0)`
                        }}
                    />
                </div>
            )
        }
        return stars;
        
    }, [rating, starImgSrc, iconSize]);
}

export default useRenderStars;