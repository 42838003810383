import fallback from "./content";
import Desktop from "./components/Desktop/Desktop";
import { useMediaQuery } from "../../../../hooks";
import Tablet from "./components/Tablet";
import Compact from "./components/Compact";
import Mobile from "./components/Mobile/Mobile";

const ProductPreview = ({ idx, content = fallback }) => {
    const isTablet = useMediaQuery("(max-width: 990px)");
    const isCompact = useMediaQuery("(max-width: 630px)");
    const isMobile = useMediaQuery("(max-width: 500px)");
    
    if (isMobile) return <Mobile content={content} />
    
    if (isCompact) return <Compact content={content} />
    
    if (isTablet) return <Tablet idx={idx} content={content} />
    
    return <Desktop idx={idx} content={content} />
}

export default ProductPreview;