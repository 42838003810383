import { useSendToSection } from "../../../../../../hooks";
import { ButtonTypeB as Button } from "../../../../../Buttons";
import styles from "./Compact.module.scss";

const Compact = ({ content }) => {
    const {
        mainImg, gallery, heading, description,
        features, cta, mirrored
    } = content;
    const sendToSection = useSendToSection();
    
    return (
        <section className={`${styles.product} ${mirrored ? styles.reverse : ""}`}>
            <div className={styles.titleBlock}>
                <h2 className={styles.heading}>{heading}</h2>
            </div>
            <div className={styles.container}>
                <div className={`${styles.gallery} ${mirrored ? styles.reverse : ""}`}>
                    <img src={mainImg.src} alt={mainImg.src} />
                    {gallery.map(({ src, alt }, id) => (
                        <img key={id} src={src} alt={alt} />
                    ))}
                </div>
                <div className={`${styles.content} ${mirrored ? styles.reverse : ""}`}>
                    <div className={styles.description}>
                        {description.map((paragraph, id) => (
                            <p key={id}>{paragraph}</p>
                        ))}
                    </div>
                    <div className={styles.features}>
                        {features.map((feature, id) => (
                            <li key={id}>{feature}</li>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.cta}>
                <Button
                    text={cta.text}
                    target={cta.target}
                    onClick={() => sendToSection("page-content", cta.target)}
                />
            </div>
        </section>
    )
}

export default Compact;