import fallback from "./content";
import Test from "./components/Test/Test";
import { useMediaQuery } from "../../../../hooks/";
import Tablet from "./components/Tablet/Tablet";
import Mobile from "./components/Mobile/Mobile";

const ServicePreview = ({ content = fallback }) => {
    const isTablet = useMediaQuery("(max-width:990px)");
    const isMobile = useMediaQuery("(max-width:400px)");

    if (isMobile) return <Mobile content={content} />
    
    if (isTablet) return <Tablet content={content} />
    
    return <Test content={content} />
}

export default ServicePreview;