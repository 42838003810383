import Card from "./components/Card";
import styles from "./Reviews.module.scss";
import content from "./content";
import { useMediaQuery } from "../../../../hooks";
import { useRenderStars } from "./hooks";

const Reviews = () => {
    const {
        heading, subheading, company,
        star, rating, amount, reviews
    } = content;
    const isMobile = useMediaQuery("(max-width: 630px)");
    
    return (
        <section className={styles.reviewSection}>
            <div className={styles.container}>
                <div className={styles.titleBlock}>
                    <h2 className={styles.heading}>{heading}</h2>
                    <p className={styles.subheading}>{subheading}</p>
                </div>
                <div className={styles.heroBlock}>
                    <h3>{company}</h3>
                    <div className={styles.hero}>
                        <span>{rating}</span>
                        {
                            isMobile ? (
                                <div className={styles.stars}>
                                    {useRenderStars(rating, star, 25)}
                                </div>
                            ) : (
                                <div className={styles.stars}>
                                    {useRenderStars(rating, star)}
                                </div>  
                            )
                        }
                        <span>({amount})</span>
                    </div>
                </div>
                <div className={styles.reviews}>
                    {reviews.map((review, id) => (
                        <Card
                            key={id} review={review}
                            stars={useRenderStars(review.rating, star, 20)}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Reviews;