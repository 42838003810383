import styles from "./Mobile.module.scss";
import { ButtonTypeB as Button } from "../../../../../Buttons";
import { useSendToSection } from "../../../../../../hooks";

const Mobile = ({ content }) => {
    const {
        mainImg, gallery, heading, description,
        features, cta
    } = content;
    const sentToSection = useSendToSection();
    
    return (
        <section className={styles.service}>
            <div className={styles.container}>
                <h2 className={styles.heading}>{heading}</h2>
                <div className={styles.description}>
                    {description.map((paragraph, id) => (
                        <p key={id}>{paragraph}</p>
                    ))}
                </div>
                <div className={styles.featuresBlock}>
                    <ul className={styles.features}>
                        {features.map((feature, id) => (
                            <li key={id}>{feature}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.gallery}>
                <img src={mainImg.src} alt={mainImg.alt} />
                {gallery.map(({ src, alt }, id) => (
                    <img key={id} src={src} alt={alt} />
                ))}
            </div>
            <div className={styles.cta}>
                <Button
                    text={cta.text}
                    target={cta.target}
                    onClick={() => useSendToSection("page-content", cta.target)}
                />
            </div>
        </section>
    )
}

export default Mobile;